import React from "react";
import ContactHero from "../components/ContactHero";
import Address from "../components/Address";

function ContactPage() {
  return (
    <>
      <ContactHero/>
      <Address/>
    </>
  );
}

export default ContactPage;
