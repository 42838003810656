import React from "react";
import { Link } from "react-router-dom";

function OurVentures() {
  return (
    <div className="bg-blac pt-5">
      <div className=" container pt-5 text-cente rounded-3 ">
        <h1 className="display-4 lh-1 fw-normal mb-5">Our Ventures</h1>
      </div>

      <div className="album mb-2">
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 g-5">

            <a href="https://pos.appnestit.com/" target="blank" className="text-decoration-none">
            <div className="col">
              <div className="card shadow px-5 ventures-card-css pt-3 border text-center border-light-subtle ">
                <div className="venture-img-card-css h-75">
                  <img
                    src="/ventures/appnest-pos.png"
                    loading="lazy"
                    alt="img"
                    className="w-100 h-100 object-fit-contain"
                    />
                </div>
                <div className="card-body px-0 h-25">
                  <p className="card-text fs-5 ">Appnest POS</p>
                </div>
              </div>
            </div>
            </a>

            <Link to={"/comming-soon"} className="text-decoration-none">
            <div className="col">
              <div className="card shadow px-5 ventures-card-css pt-3 border text-center border-light-subtle ">
                <div className="venture-img-card-css h-75">
                  <img
                    src="/ventures/appnest-people.jpeg"
                    loading="lazy"
                    alt="img"
                    className="w-100 h-100 object-fit-contain px-3 py-4"
                  />
                </div>

                <div className="card-body px-0 h-25 ">
                  <p className="card-text fs-5 ">Appnest People</p>
                </div>
              </div>
            </div>
            </Link>

            <Link to={"/comming-soon"} className="text-decoration-none">
            <div className="col">
              <div className="card shadow px-5 ventures-card-css pt-3 border text-center border-light-subtle ">
                <div className="venture-img-card-css h-75">
                  <img
                    src="/ventures/appnest-spa.png"
                    loading="lazy"
                    alt="img"
                    className="w-100 h-100 object-fit-contain px-3 py-4"
                  />
                </div>
                <div className="card-body px-0 h-25 ">
                  <p className="card-text fs-5 ">Appnest Spa</p>
                </div>
              </div>
            </div>
            </Link>

            <a href="https://play.google.com/store/apps/details?id=com.it.karter" target="blank" className="text-decoration-none">
            <div className="col">
              <div className="card shadow px-5 ventures-card-css pt-3 border text-center border-light-subtle ">
                <div className="venture-img-card-css h-75">
                  <img
                    src="/ventures/karter.png"
                    loading="lazy"
                    alt="img"
                    className="w-100 h-100 object-fit-contain"
                  />
                </div>

                <div className="card-body px-0 h-25 ">
                  <p className="card-text fs-5 ">Karter</p>
                </div>
              </div>
            </div>
            </a>

            <a href="https://play.google.com/store/apps/details?id=com.it.cabifydriver" target="blank" className="text-decoration-none">
            <div className="col">
              <div className="card shadow px-5 ventures-card-css pt-3 border text-center border-light-subtle ">
                <div className="venture-img-card-css h-75">
                  <img
                    src="/ventures/jetdrop.png"
                    loading="lazy"
                    alt="img"
                    className="w-100 h-100 object-fit-contain"
                  />
                </div>

                <div className="card-body px-0 h-25 ">
                  <p className="card-text fs-5 ">Jetdrop</p>
                </div>
              </div>
            </div>
            </a>
            
            <a href="https://play.google.com/store/apps/details?id=com.colorcode.vidyalaya.admin" target="blank" className="text-decoration-none">
            <div className="col">
              <div className="card shadow px-5 ventures-card-css pt-3 border text-center border-light-subtle ">
                <div className="venture-img-card-css h-75">
                  <img
                    src="/ventures/Vidyalay.png"
                    loading="lazy"
                    alt="img"
                    className="w-100 h-100 object-fit-contain"
                  />
                </div>

                <div className="card-body px-0 h-25 ">
                  <p className="card-text fs-5 ">Vidhyalay</p>
                </div>
              </div>
            </div>
            </a>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurVentures;
