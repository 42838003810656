import React from 'react'

function PageNotFound() {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">

    <div className=' py-5 display-3 fw-semibold text-center'>PageNotFound</div>
    </div>
  )
}

export default PageNotFound