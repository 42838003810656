import React from 'react'

function CommingSoon() {
  return (
    
    <div className="d-flex justify-content-center align-items-center vh-100">

    <div className=' py-5 display-3 fw-semibold text-center'>Comming Soon...</div>
    </div>
  )
}

export default CommingSoon